var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Case, Default, Switch } from 'react-when-then';
import { IStatus } from '../../../@types/status';
import ContainerLayout from '../../../components/ContainerLayout';
import { LinearLoader } from '../../../components/Loader';
import { getOneSection } from '../../../redux/slices/sections/actions';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SectionForm from '../../../sections/@dashboard/Configuration/Sections/SectionForm';
var SectionsView = function () {
    var id = useParams().id;
    useEffect(function () {
        dispatch(getOneSection(id || ''));
    }, [dispatch, id]);
    var _a = useSelector(function (store) { return store.sections; }), section = _a.section, status = _a.status;
    var isLoading = status === IStatus.LOADING;
    return (_jsx(ContainerLayout, __assign({ helmet: "Section", heading: "View Section", links: [
            {
                name: 'Sections',
                href: PATH_DASHBOARD.configuration.sections.root,
            },
            { name: 'View Section' },
        ] }, { children: _jsxs(Switch, { children: [_jsx(Case, __assign({ when: isLoading }, { children: _jsx(LinearLoader, {}) })), _jsx(Default, { children: _jsx(SectionForm, { isView: true, currentSection: section || undefined, isEdit: false }, "".concat(section === null || section === void 0 ? void 0 : section._id, "___").concat(new Date())) })] }) })));
};
export default SectionsView;
